export const TableConfig = {
  tables: [
    {
      name: "Students",
      items: [
        {
          name: "CRN ID",
          id: "_source.student_details.crn_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "School ID",
          id: "_source.student_details.school_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "NAME",
          id: "_source.student_details.first_name",
          type: "default",
          displayTable: true,
        },
        {
          name: "Event Name",
          id: "_source.event_details.name",
          type: "default",
          displayTable: true,
        },
        {
          name: "Event Date",
          id: "_source.event_details.start_date_time",
          type: "time",
          displayTable: true,
        },
        {
          name: "PHONE",
          id: "_source.student_details.phone",
          type: "default",
          displayTable: true,
        },
        {
          name: "GRADE",
          id: "_source.student_details.grade_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "LOGIN ID",
          id: "_source.student_details.admission_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "REG. SOURCE",
          id: "_source.student_details.registration_source",
          type: "default",
          displayTable: true,
        },
        {
          name: "REGISTERED ON",
          id: "_source.student_details.registration_date",
          type: "time",
          displayTable: true,
        },
        {
          name: "LAST LOGIN",
          id: "_source.student_details.last_active_at",
          type: "time",
          displayTable: true,
          sort: true,
        },
        {
          name: "ATTEMPT",
          id: "_source.exam_attempted",
          type: "boolean",
          displayTable: true,
        },
      ],
    },
    {
      name: "Events",
      items: [
        {
          name: "CRN ID",
          id: "_source.student_details.crn_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "NAME",
          id: "_source.student_details.first_name",
          type: "default",
          displayTable: true,
        },
        {
          name: "PHONE",
          id: "_source.student_details.phone",
          type: "phone",
          displayTable: true,
        },
        {
          name: "GRADE",
          id: "_source.student_details.grade_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "LOGIN ID",
          id: "_source.student_details.admission_id",
          type: "default",
          displayTable: true,
        },

        {
          name: "REG. SOURCE",
          id: "_source.student_details.registration_source",
          type: "default",
          displayTable: true,
        },
        {
          name: "REGISTERED ON",
          id: "_source.registration_date",
          type: "time",
          displayTable: true,
        },
        {
          name: "LAST LOGIN",
          id: "_source.student_details.last_active_at",
          type: "time",
          displayTable: true,
          sort: true,
        },
        {
          name: "ATTEMPT",
          id: "_source.exam_attempted",
          type: "boolean",
          displayTable: true,
        },
      ],
    },
    {
      name: "StudentsBYEvent",
      items: [
        {
          name: "CRN ID",
          id: "crn_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "NAME",
          id: "student_details.first_name",
          type: "default",
          displayTable: true,
        },
        {
          name: "PHONE",
          id: "student_details.phone",
          type: "phone",
          displayTable: true,
        },
        {
          name: "GRADE",
          id: "student_details.grade_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "LOGIN ID",
          id: "student_details.admission_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "EXAM",
          id: "event_details.exam_name",
          type: "default",
          displayTable: true,
        },
        {
          name: "EVENT ID",
          id: "event_id",
          type: "default",
          displayTable: true,
        },
        {
          name: "REG. SOURCE",
          id: "student_details.registration_source",
          type: "default",
          displayTable: true,
        },
        {
          name: "REGISTERED ON",
          id: "student_details.registration_date",
          type: "time",
          displayTable: true,
        },

        // {
        //     name: "LAST LOGIN",
        //     id: "student_details.last_active_at",
        //     type: "time",
        //     displayTable: true,
        //     sort: true,
        // },
        {
          name: "ATTEMPT",
          id: "exam_attempted",
          type: "boolean",
          displayTable: true,
        },
        // {
        //   name: "LSQ Status",
        //   id: "lsq_sync_status",
        //   type: "boolean",
        //   displayTable: true,
        // },
      ],
    },
  ],
};
